import { createStore } from "vuex";
import axios from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();
export default createStore({
  state: {
    lang: localStorage.getItem("lang") || "ru",
    favoritesArray: JSON.parse(localStorage.getItem("favorite")) || [],
    cartLength: 0,
    basketOpened: false,
    favouriteOpened: false,
    cartList: JSON.parse(localStorage.getItem("cart_products")) || [],
    homePage: [],
    loader: false,
    cdn: process.env.VUE_APP_CDN,
    product: [],
    products: [],
    city: [],
    baseCity: [],
    subCategory: [],
    productsById: [],
    filterOpened: false,
    myOredersOpened: false,
    filterData: [],
    spinnerSearch: false,
    categoryList: [],
    pageData: [],
    orders: [],
    orderById: [],
    reviewData: [],
    shopsOpened: false,
  },
  getters: {
    GET_FAVOURITE_COUNT: (state) => state.favoritesArray,
    GET_CART_LENGTH: (state) => state.cartLength,
    GET_CART_LIST: (state) => state.cartList,
    GET_FAVOURITE_LIST: (state) => state.favoritesArray,
    GET_HOME_PAGE: (state) => state.homePage,
    GET_LOADER: (state) => state.loader,
    GET_CDN: (state) => state.cdn,
    GET_PRODUCTS: (state) => state.products,
    GET_PRODUCT: (state) => state.product,
    GET_CITY: (state) => state.city,
    GET_BASE_CITY: (state) => state.baseCity,
    GET_SUBCATEGORY: (state) => state.subCategory,
    GET_PRODUCTS_BY_ID: (state) => state.productsById,
    GET_BASKET_OPENED: (state) => state.basketOpened,
    GET_FILTER_OPENED: (state) => state.filterOpened,
    GET_FILTER: (state) => state.filterData,
    GET_SPINNER_SEARCH: (state) => state.spinnerSearch,
    GET_CATEGORY: (state) => state.categoryList,
    GET_PAGE_DATA: (state) => state.pageData,
    GET_ORDERS: (state) => state.orders,
    GET_ORDER_BY_ID: (state) => state.orderById,
    GET_REVIEW: (state) => state.reviewData,
    GET_SHOPS: (state) => state.shopsOpened,

  },
  mutations: {
    ADD_FAVORITES(state, product) {
      let favoriteStorage = JSON.parse(localStorage.getItem("favorite"));
      localStorage.setItem("favorite", JSON.stringify([product]));
      if (favoriteStorage == null) {
        localStorage.setItem("favorite", JSON.stringify([product]));
        state.favoritesArray = JSON.parse(localStorage.getItem("favorite"));
        toast.success("Добавлено в избранные");
      } else if (favoriteStorage == []) {
        favoriteStorage.push(product);
        localStorage.setItem("favorite", JSON.stringify(favoriteStorage));
        state.favoritesArray = favoriteStorage;
        toast.success("Добавлено в избранные");
      } else if (favoriteStorage.find((item) => item.id == product.id)) {
        favoriteStorage.forEach((item, index) => {
          if (item.id == product.id) {
            favoriteStorage.splice(index, 1);
          }
        });
        localStorage.setItem("favorite", JSON.stringify(favoriteStorage));
        state.favoritesArray = favoriteStorage;
        toast.error("Удаленно из избранного");
      } else {
        favoriteStorage.push(product);
        state.favoritesArray = favoriteStorage;
        localStorage.setItem("favorite", JSON.stringify(favoriteStorage));
        toast.success("Добавлено в избранные");
      }
    },
    ADD_TO_CART(state, { product, countValue, shop_id }) {
      product["count"] = countValue ? countValue : 1;
      product["shop_id"] = shop_id
      let cartList = JSON.parse(localStorage.getItem("cart_products"));
      localStorage.setItem("cart_products", JSON.stringify([product]));
      if (cartList == null) {
        localStorage.setItem("cart_products", JSON.stringify([product]));
        state.cartLength = localStorage.getItem("cart_products").length;
        state.cartList = cartList;
        toast.success("Добавлено в корзину");
      } else if (cartList == []) {
        cartList.push(product);
        localStorage.setItem("cart_products", JSON.stringify(cartList));
        state.cartLength = cartList.length;
        state.cartList = cartList;
        toast.success("Добавлено в корзину");
      } else if (cartList.find((item) => item.id == product.id)) {
        cartList.forEach((item, index) => {
          if (item.id == product.id) {
            cartList.splice(index, 1);
          }
        });
        localStorage.setItem("cart_products", JSON.stringify(cartList));
        state.cartLength = cartList.length;
        state.cartList = cartList;
        toast.success("Добавлено в корзину");
      } else {
        cartList.push(product);
        state.cartLength = cartList.length;
        localStorage.setItem("cart_products", JSON.stringify(cartList));
        state.cartList = cartList;
        toast.success("Добавлено в корзину");
      }
    },
    ADD_TO_CART_PRODUCT(state, { product, countValue }) {
      product["count"] = countValue ? countValue : 1;
      let cartList = JSON.parse(localStorage.getItem("cart_products"));
      if (cartList && cartList.find((item) => item.id == product.id)) {
        cartList.filter((i) => {
          if (i.id === product.id) {
            i.count = countValue;
            localStorage.setItem("cart_products", JSON.stringify(cartList));
          }
        });
        return false;
      } else if (cartList !== null) {
        cartList.push(product);
        localStorage.setItem("cart_products", JSON.stringify(cartList));
        state.cartLength = localStorage.getItem("cart_products");
        
      } else {
        localStorage.setItem("cart_products", JSON.stringify([product]));
        state.cartLength = localStorage.getItem("cart_products");
        
      }
    },
    DELETE_PRODUCT(state, { id }) {
      let cartList = state.cartList.filter((t) => t.id !== id);
      localStorage.setItem("cart_products", JSON.stringify(cartList));
      state.cartList = cartList;
      toast.error("Удаленно из корзины");
      this.dispatch("CHECK_CARD_LIST")

    },
    SET_CART_LENGTH(state, length) {
      state.cartLength = length;
    },
    SET_CART_LIST(state, cartList) {
      state.cartList = cartList;
    },
    SET_FAVOURITE_LIST(state, favoritesArray) {
      state.fa = favoritesArray;
    },
    showHide(state) {
      state.basketOpened = !state.basketOpened;
    },
    showHide2(state) {
      state.favouriteOpened = !state.favouriteOpened;
    },
    showHide3(state) {
      state.filterOpened = !state.filterOpened;
    },
    showHide4(state) {
      state.myOredersOpened = !state.myOredersOpened;
    },
    showHide5(state) {
      state.shopsOpened = !state.shopsOpened;
    },
    SET_CART_COUNT(state, { id, count }) {
      state.cartList.forEach((item, idx) => {
        if (item.id == id) {
          state.cartList[idx].count = count;
        }
      });
      localStorage.setItem("cart_products", JSON.stringify(state.cartList));
    },
    SET_HOME_PAGE(state, homePage) {
      state.homePage = homePage;
    },
    SET_LOADER(state) {
      state.loader = !state.loader;
    },
    SET_LOADER_FALSE(state) {
      state.loader = false;
    },
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    SET_PRODUCT(state, product) {
      state.product = product;
    },
    SET_CITY(state, city) {
      state.city = city;
    },
    SET_BASE_CITY(state, baseCity) {
      state.baseCity = baseCity;
    },
    SET_SUBCATEGORY(state, subCategory) {
      state.subCategory = subCategory;
    },
    SET_PRODUCTS_BY_ID(state, productsById) {
      state.productsById = productsById;
    },
    SET_FILTER(state, filterData) {
      state.filterData = filterData;
    },
    SET_SPINNER_SEARCH(state) {
      state.spinnerSearch = !state.spinnerSearch;
    },
    SET_FILTER_TO_PRODUCTS(state){
      state.products = state.filterData
    },
    SET_CATEGORY(state, categoryList){
      state.categoryList = categoryList
    },
    SET_PAGE_DATA(state, pageData){
      state.pageData = pageData
    },
    SET_ORDERS(state, orders){
      state.orders = orders
    },
    SET_ORDER_BY_ID(state, orderById){
      state.orderById = orderById
    },
    SET_REVIEW(state, reviewData){
      state.reviewData = reviewData
    }
  },
  actions: {
    CART_LENGTH_ACTION({ commit }, length) {
      commit("SET_CART_LENGTH", length);
    },
    CHECK_CARD_LIST({ commit }) {
      commit(
        "SET_CART_LIST",
        JSON.parse(localStorage.getItem("cart_products"))
      );
    },
    CHECK_FAVOURITE_LIST({ commit }) {
      commit(
        "SET_FAVOURITE_LIST",
        JSON.parse(localStorage.getItem("cart_products"))
      );
    },
    async FETCH_HOME_PAGE({ commit, state }) {
      commit("SET_LOADER");
      try {
        const data = await axios.get("homepage", {});
        if (data.status === 200) {
          commit("SET_HOME_PAGE", data.data);
          commit("SET_LOADER_FALSE");
        }
      } catch (error) {
        // alert(error);
        commit("SET_LOADER_FALSE");
        console.log(error);
      }
    },
    LOADER({ commit }) {
      commit("SET_LOADER");
    },
    async FETCH_PRODUCTS({ commit, state }, { subCategory }) {
      commit("SET_LOADER");
      try {
        const data = await axios.get(`products/${subCategory}`, {});
        if (data.status === 200) {
          commit("SET_LOADER");
          commit("SET_PRODUCTS", data.data);
        }
      } catch (error) {
        commit("SET_LOADER");
        console.log(error);
      }
    },
    async FETCH_PRODUCT({ commit, state }, { subCategory }) {
      commit("SET_LOADER");
      try {
        const data = await axios.get(`product/${subCategory}`, {});
        if (data.status === 200) {
          commit("SET_LOADER");
          commit("SET_PRODUCT", data.data.data);
        }
      } catch (error) {
        commit("SET_LOADER");
        console.log(error);
      }
    },
    async FETCH_CITY({ commit, state }) {
      try {
        const data = await axios.get(`cities`);
        if (data.status === 200) {
          commit("SET_CITY", data.data.cities);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async CHECK_BASE_CITY({ commit }, { city }) {
      if (localStorage.getItem("location")) {
        let logCity = city.find(
          (item) => item.id == localStorage.getItem("location")
        );
        commit("SET_BASE_CITY", logCity.name);
      } else {
        commit("SET_BASE_CITY", city[0].name);
      }
    },
    async FETCH_SUBCATEGORY({ commit, state }, { subCategory }) {
      commit("SET_LOADER");
      try {
        const data = await axios.get(`category/${subCategory}`, {});
        if (data.status === 200) {
          commit("SET_LOADER");
          commit("SET_SUBCATEGORY", data.data);
        }
      } catch (error) {
        commit("SET_LOADER");
        console.log(error);
      }
    },
    async FETCH_PRODUCTS_BY_ID({ commit, state }, { id }) {
      commit("SET_LOADER");
      try {
        const data = await axios.post(`products-byId`, {
          ids: id,
        });
        if (data.status === 200) {
          commit("SET_LOADER");
          commit("SET_PRODUCTS_BY_ID", data.data.products);
        }
      } catch (error) {
        commit("SET_LOADER");
        console.log(error);
      }
    },
    async FETCH_FILTER({ commit, state }, { category, sortBy, search, brands,SetToFilter,price_min,price_max }) {
      commit("SET_LOADER");
      commit("SET_SPINNER_SEARCH")
      try {
        const data = await axios.get(`filter`, {
          params: {
            category: category,
            sortBy: sortBy,
            title: search,
            brands: brands,
            price_min: price_min,
            price_max: price_max
          },
        });
        if (data.status === 200) {
          commit("SET_LOADER");
          if(SetToFilter === true){
            commit("SET_PRODUCTS", data.data);
          }
          commit("SET_SPINNER_SEARCH")
          commit("SET_FILTER", data.data);
        }
      } catch (error) {
        commit("SET_LOADER");
        commit("SET_SPINNER_SEARCH")
        console.log(error);
      }
    },
    async GET_CITY_SEARCH_RESULT({ commit }, { text }) {
      commit("SET_SPINNER_SEARCH")
      try {
      let result = await axios.get("cities", {
        params: {
          search: text,
        },

      });
      commit("SET_SPINNER_SEARCH")
      commit("SET_CITY", result.data.cities);
      }
      catch (error) {
        commit("SET_SPINNER_SEARCH")
        console.log(error);
      }
      
    },
    async FETCH_CATEGORY({ commit }) {
      commit("SET_LOADER");
      try {
        const data = await axios.get(`categories`, {});
        if (data.status === 200) {
          commit("SET_LOADER");
          commit("SET_CATEGORY", data.data);
        }
      } catch (error) {
        commit("SET_LOADER");
        console.log(error);
      }
    },
    async FETCH_PAGE_DATA({ commit },{page}) {
      commit("SET_LOADER");
      try {
        const data = await axios.get(`${page}`);
        if (data.status === 200) {
          commit("SET_LOADER");
          commit("SET_PAGE_DATA", data.data);
        }
      } catch (error) {
        commit("SET_LOADER");
        console.log(error);
      }
    },
    async FETCH_MY_ORDERS({ commit }) {
      commit("SET_LOADER");
      try {
        const data = await axios.get(`get-orders`);
        if (data.status === 200) {
          commit("SET_LOADER");
          commit("SET_ORDERS", data.data);
        }
      } catch (error) {
        commit("SET_LOADER");
        console.log(error);
      }
    },
    async FETCH_MY_ORDER_BY_ID({ commit },{id}) {
      commit("SET_LOADER");
      try {
        const data = await axios.get(`order/${id}`);
        if (data.status === 200) {
          commit("SET_LOADER");
          commit("SET_ORDER_BY_ID", data.data.order);
        }
      } catch (error) {
        commit("SET_LOADER");
        console.log(error);
      }
    },
    async FETCH_REVIEW_BY_ID({ commit },{id}) {
      commit("SET_LOADER");
      try {
        const data = await axios.get(`product/${id}/review`);
        if (data.status === 200) {
          commit("SET_LOADER");
          commit("SET_REVIEW", data.data);
        }
      } catch (error) {
        commit("SET_LOADER");
        console.log(error);
      }
    },
  },
  modules: {},
});
