<template>
  <div class="search">
    <div class="search__content">
      <div class="search__items">
        <div class="search__items-button">

          <button> <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15 15L12.2029 12.2029M12.2029 12.2029C13.3905 11.0153 14.125 9.37468 14.125 7.5625C14.125 3.93813 11.1869 1 7.5625 1C3.93813 1 1 3.93813 1 7.5625C1 11.1869 3.93813 14.125 7.5625 14.125C9.37468 14.125 11.0153 13.3905 12.2029 12.2029Z"
                stroke="#9BA1A8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
          <img v-if="spinner" src="@/assets/images/svg/Rolling-1s-200px.svg" />
          <input @keyup="searchHandle($event)" v-model="searchText" placeholder="Поиск" ref="search" type="text" />
          <button v-if="btnFilter" @click="$store.commit('showHide3')"><svg width="18" height="18" viewBox="0 0 18 18"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M2 4C2 2.89543 2.89543 2 4 2C5.10457 2 6 2.89543 6 4C6 4.55256 5.77724 5.05119 5.41421 5.41421C5.05119 5.77724 4.55256 6 4 6C2.89543 6 2 5.10457 2 4ZM4 0C1.79086 0 0 1.79086 0 4C0 6.20914 1.79086 8 4 8C5.10429 8 6.10566 7.55119 6.82843 6.82843C7.32527 6.33158 7.69266 5.70308 7.87379 5L17 5C17.5523 5 18 4.55228 18 4C18 3.44772 17.5523 3 17 3L7.87398 3C7.42994 1.27477 5.86384 0 4 0ZM12 14C12 12.8954 12.8954 12 14 12C15.1046 12 16 12.8954 16 14C16 14.5526 15.7772 15.0512 15.4142 15.4142C15.0512 15.7772 14.5526 16 14 16C12.8954 16 12 15.1046 12 14ZM14 10C12.1362 10 10.5701 11.2748 10.126 13L1 13C0.447715 13 0 13.4477 0 14C0 14.5523 0.447715 15 1 15L10.126 15C10.5701 16.7252 12.1362 18 14 18C15.1043 18 16.1057 17.5512 16.8284 16.8284C17.5512 16.1057 18 15.1043 18 14C18 11.7909 16.2091 10 14 10Z"
                fill="#1D8EF6" />
            </svg>
          </button>
          <i v-if="count > 0"><span class="f-12">{{ count }}</span></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: ['count', 'btnFilter','isOpen'],
  data: () => ({
    searchText: ''
  }),
  computed: {
    ...mapGetters({spinner: 'GET_SPINNER_SEARCH'})
  },
  methods: {
    ...mapActions(['GET_CITY_SEARCH_RESULT', 'FETCH_CITY']),
    async searchHandle(e) {
      let q = e.target.value;
      if (q.length >= 2) {
        await this.GET_CITY_SEARCH_RESULT({ text: q, lang: "ru" }).then((res) => {
          this.isResult = true;
        });
      } else if (q.length < 1) {
        this.FETCH_CITY()
      }
      else {
        this.isResult = false;
      }
    },
  },
  watch: {
    isOpen(){
      if(this.isOpen === false){
        this.searchText = ''
      }
    }
  }
}
</script>

<style>

</style>