<template>
  <div class="fmtop">
    <div class="fmtop__items">
      <div class="fmtop__items-tabs">
        <div class="fmtop__items-tabs-tab">
          <span @click="goFavourite" class="f-12">
            Избранные
          </span>
        </div>
        <div v-if="GET_CART_LIST" class="fmtop__items-tabs-tab">
          <span @click="goBasket" class="f-12">
            Корзина
          </span>
          <p :style="GET_CART_LIST.length > 0 ? 'background: #1D8EF6;' : 'background: #9BA1A8;'" class="f-12">
            {{ GET_CART_LIST.length }}</p>
        </div>
        <div class="fmtop__items-tabs-tab">
          <span @click="goOrder" class="f-12">
            Мои заказы
          </span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    favoriteList: null,
    cartList: null
  }),
  created() {
    
  },
  mounted(){
  },
  computed: {
    ...mapGetters(['GET_CART_LIST',])
  },
  methods: {
    goBasket() {
      this.$store.commit('showHide')
    },
    goFavourite(){
      this.$store.commit('showHide2')
    },
    goOrder() {
      this.$store.commit('showHide4')
    }
  }

}
</script>

<style>

</style>