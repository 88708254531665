<template>
    <div class="header">
        <div class="header__content container">
            <div class="header__items">
                <div class="header__items-top" v-if="$route.name === 'Shop'"
                    :class="{ change_color: scrollPosition > 50 }">
                    <div class="header__items-top-mobile">
                        <div :class="{ change_svg: scrollPosition > 50 }" class="header__items-top-mobile-image">
                            <svg width="113" height="20" viewBox="0 0 113 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18.5442 3.10078H21.2535L24.3197 11.9048H24.4089L27.475 3.10078H30.2289V3.33334L23.974 20H21.2312V19.7785L23.1044 14.7619L22.8368 14.7176L18.5442 3.33334V3.10078Z"
                                    fill="white" />
                                <path
                                    d="M27.4635 3.10078H30.1729L33.239 11.9048H33.3282L36.3943 3.10078H39.1482V3.33334L32.8934 20H30.1506V19.7785L32.0237 14.7619L31.7561 14.7176L27.4635 3.33334V3.10078Z"
                                    fill="white" />
                                <path
                                    d="M57.8885 15.0055C56.3573 15.0055 55.2386 14.4703 54.5325 13.3998H54.4433L54.1423 14.8062H51.8455L51.7563 0H54.3541L54.4433 4.5072H54.5325C55.2386 3.4367 56.3573 2.90145 57.8885 2.90145C59.4346 2.90145 60.6462 3.45885 61.5233 4.57365C62.378 5.68845 62.8055 7.14286 62.8055 8.93688C62.8055 10.7752 62.3706 12.2481 61.501 13.3555C60.6164 14.4555 59.4123 15.0055 57.8885 15.0055ZM57.253 12.7243C58.2416 12.7243 58.9812 12.3662 59.4717 11.6501C59.94 10.9708 60.1742 10.0664 60.1742 8.93688C60.1742 7.81469 59.94 6.914 59.4717 6.23478C58.996 5.5408 58.2564 5.1938 57.253 5.1938C56.2793 5.1938 55.5545 5.5408 55.0788 6.23478C54.5957 6.93614 54.3541 7.83684 54.3541 8.93688C54.3541 10.0443 54.5957 10.9487 55.0788 11.6501C55.5694 12.3662 56.2941 12.7243 57.253 12.7243Z"
                                    fill="white" />
                                <path
                                    d="M3.48996 14.8062C1.16342 14.8062 0.000153626 13.6729 0.000153626 11.4064L0 6.03497e-06H2.59799V3.10078H5.45227V5.30455H2.59799V10.9524C2.59799 11.5061 2.72064 11.9048 2.96593 12.1484C3.22608 12.4068 3.6126 12.536 4.12548 12.536H5.49687V14.8062H3.48996Z"
                                    fill="white" />
                                <path
                                    d="M44.1439 15.0277C45.6752 15.0277 46.7939 14.4917 47.5 13.4198H47.5892L47.8903 14.8281H50.1872V2.9317H47.5892V4.51565H47.5C46.7939 3.44375 45.6752 2.90779 44.1439 2.90779C42.5978 2.90779 41.3861 3.46592 40.509 4.58218C39.6542 5.69843 39.2268 7.15474 39.2268 8.9511C39.2268 10.7918 39.6616 12.2666 40.5313 13.3755C41.4159 14.4769 42.6201 15.0277 44.1439 15.0277ZM44.7795 12.7434C43.7908 12.7434 43.0512 12.3849 42.5606 11.6678C42.0923 10.9877 41.8582 10.0821 41.8582 8.9511C41.8582 7.82745 42.0923 6.92558 42.5606 6.24547C43.0363 5.55059 43.776 5.20314 44.7795 5.20314C45.7532 5.20314 46.478 5.55059 46.9537 6.24547C47.4369 6.94775 47.6784 7.84963 47.6784 8.9511C47.6784 10.06 47.4369 10.9655 46.9537 11.6678C46.4631 12.3849 45.7383 12.7434 44.7795 12.7434Z"
                                    fill="white" />
                                <path
                                    d="M11.3387 15.0277C12.87 15.0277 13.9887 14.4917 14.6949 13.4198H14.7841L15.0851 14.8281H17.382V2.9317H14.7841V4.51565H14.6949C13.9887 3.44375 12.87 2.90779 11.3387 2.90779C9.79259 2.90779 8.58096 3.46592 7.70383 4.58218C6.849 5.69843 6.42158 7.15474 6.42158 8.9511C6.42158 10.7918 6.85643 12.2666 7.72613 13.3755C8.61069 14.4769 9.81489 15.0277 11.3387 15.0277ZM11.9743 12.7434C10.9856 12.7434 10.246 12.3849 9.75542 11.6678C9.28713 10.9877 9.05298 10.0821 9.05298 8.9511C9.05298 7.82745 9.28713 6.92558 9.75542 6.24547C10.2312 5.55059 10.9708 5.20314 11.9743 5.20314C12.948 5.20314 13.6728 5.55059 14.1485 6.24547C14.6317 6.94775 14.8733 7.84963 14.8733 8.9511C14.8733 10.06 14.6317 10.9655 14.1485 11.6678C13.6579 12.3849 12.9332 12.7434 11.9743 12.7434Z"
                                    fill="white" />
                                <path
                                    d="M72.3264 14.9999C74.8458 14.9999 76.4985 13.6977 76.4985 11.724C76.4985 9.46538 74.5838 8.69217 72.407 8.0614C71.0365 7.67479 70.2706 7.36958 70.2706 6.75915C70.2706 6.10803 70.8954 5.68073 71.9031 5.68073C72.9512 5.68073 73.7171 6.25046 74.0799 7.41027L76.2969 6.7795C75.8132 4.86682 74.0396 3.60527 71.9031 3.60527C69.6458 3.60527 67.9729 4.96856 67.9729 6.79984C67.9729 8.77356 69.6256 9.44503 71.6613 10.0351C73.2535 10.5235 74.2008 10.9101 74.2008 11.8257C74.2008 12.4972 73.4954 12.9245 72.3264 12.9245C70.7543 12.9245 69.9682 12.2123 69.6256 10.5845L67.4287 11.1542C67.8318 13.6163 69.6055 14.9999 72.3264 14.9999Z"
                                    fill="white" fill-opacity="0.48" />
                                <path
                                    d="M83.8786 6.08768V3.91048H81.2382V1.28564H78.8196V3.91048H77.0661V6.08768H78.8196V11.5612C78.8196 13.8401 79.7468 14.6947 82.1452 14.6947H83.4351V12.3547H82.3669C81.5003 12.3547 81.2382 12.1309 81.2382 11.4391V6.08768H83.8786Z"
                                    fill="white" fill-opacity="0.48" />
                                <path
                                    d="M89.7569 14.9999C92.8608 14.9999 94.9569 12.7006 94.9569 9.3026C94.9569 5.90455 92.8608 3.60527 89.7569 3.60527C86.6732 3.60527 84.577 5.90455 84.577 9.3026C84.577 12.7006 86.6732 14.9999 89.7569 14.9999ZM89.7569 12.7617C88.0236 12.7617 86.9957 11.4594 86.9957 9.3026C86.9957 7.14575 88.0236 5.84351 89.7569 5.84351C91.4902 5.84351 92.5383 7.14575 92.5383 9.3026C92.5383 11.4594 91.4902 12.7617 89.7569 12.7617Z"
                                    fill="white" fill-opacity="0.48" />
                                <path
                                    d="M96.7701 14.6947H99.1887V8.65148C99.1887 6.92193 99.8336 6.08768 101.063 6.08768C101.426 6.08768 101.769 6.14872 102.212 6.27081L102.635 3.89014C102.252 3.76805 101.829 3.70701 101.345 3.70701C100.317 3.70701 99.5313 4.15466 99.0879 4.84647H99.0476V3.91048H96.7701V14.6947Z"
                                    fill="white" fill-opacity="0.48" />
                                <path
                                    d="M107.96 14.9999C109.995 14.9999 111.567 14.125 112.535 12.4158L110.761 11.1135C109.955 12.3751 109.149 12.8838 107.96 12.8838C106.428 12.8838 105.46 11.724 105.38 10.0148H112.857V9.18051C112.857 5.86386 110.882 3.60527 107.919 3.60527C104.977 3.60527 102.941 5.9249 102.941 9.3026C102.941 12.7006 104.977 14.9999 107.96 14.9999ZM105.4 8.22418C105.481 6.79984 106.529 5.72142 107.939 5.72142C109.451 5.72142 110.378 6.71845 110.459 8.22418H105.4Z"
                                    fill="white" fill-opacity="0.48" />
                            </svg>

                        </div>
                        <div v-if="baseCity" :class="{ change_location: scrollPosition > 50 }"
                            class="header__items-top-mobile-location">
                            <svg width="9" height="12" viewBox="0 0 9 12" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M0 4.4183C0 1.97814 1.97814 0 4.4183 0C6.85846 0 8.83661 1.97814 8.83661 4.4183C8.83661 6.0537 7.94809 7.48157 6.62745 8.24552C5.99225 8.61296 5.42499 9.15026 5.26994 9.86752L4.8915 11.6182C4.78145 12.1273 4.05516 12.1273 3.9451 11.6182L3.56667 9.86752C3.41162 9.15026 2.84436 8.61296 2.20915 8.24552C0.888518 7.48157 0 6.0537 0 4.4183ZM4.41832 6.3513C5.48589 6.3513 6.35133 5.48586 6.35133 4.41829C6.35133 3.35072 5.48589 2.48528 4.41832 2.48528C3.35075 2.48528 2.48532 3.35072 2.48532 4.41829C2.48532 5.48586 3.35075 6.3513 4.41832 6.3513Z"
                                    fill="white" />
                            </svg>

                            <button @click="changeCity" v-if="baseCity">{{ baseCity }}</button>

                        </div>

                    </div>
                </div>
                <div class="header__items-bottom">
                    <FooterSecond />
                    <!-- <FooterMain v-if="$route.name === 'Shop' || $route.name === 'home'" /> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FooterMain from '@/components/layouts/FooterMobileMain.vue'
import FooterSecond from '@/components/layouts/FooterMobileSecond.vue'
import { mapActions, mapGetters } from 'vuex';
export default {
    props: ['city'],
    components: {
        FooterMain,
        FooterSecond
    },
    data: () => ({
        scrollPosition: null,
    }),
    created() {
        
    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll);
    },
    computed: {
        ...mapGetters({ baseCity: 'GET_BASE_CITY' })
    },
    methods: {
        updateScroll() {
            this.scrollPosition = window.scrollY
        },
        changeCity() {
            this.$emit('openModal')
        }

    },
}
</script>

<style lang="scss" scoped>
.change_color {
    background: rgba(245, 246, 246, 0.88);

    backdrop-filter: blur(20px);
}

.change_svg {
    svg {
        path {
            fill: #000000 !important;
            // fill: transparent;
        }

        text {
            fill: #000000 !important;

        }
    }
}

.change_location {
    svg {

        path {
            fill: #1D8EF6;
        }
    }

    button {
        color: #1D8EF6 !important;
    }
}
</style>