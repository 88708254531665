<template>
  <div class="home">
    <div v-if="home" class="home__content">
      <swiper-component />
      <div class="home__items">

        <!-- <img style="background: rgba(0, 0, 0, 0.24);" src="@/assets/images/png/bannerimg.png" alt=""> -->
        <SearchComponent class="container" />
        <categoryComponentVue :categoryList="home.categories" />
        <recentlySeenVue />
        <popularBlock />
        <div class="home__items-link container">
          <router-link class="f-14 btn_gray" to="/">Все популярные товары</router-link>
          <router-link class="f-14  btn_gray" to="/">Все рекомендуемые</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchComponent from '@/components/searchComponent.vue'
import categoryComponentVue from '@/components/categoryComponent.vue';
import recentlySeenVue from '@/components/recentlySeen.vue';
import popularBlock from '@/components/popularBlock.vue';
import SwiperComponent from '@/components/swiperComponent.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'HomeView',
  components: {
    SearchComponent,
    categoryComponentVue,
    recentlySeenVue,
    popularBlock,
    SwiperComponent
  },
  data: () => ({
    isOpenn: true,
  }),
  created(){
    this.FETCH_HOME_PAGE()
    // this.showPosition()
  },
  computed: {
    ...mapGetters({home: 'GET_HOME_PAGE'})
  },
  methods:{
    ...mapActions(['FETCH_HOME_PAGE']),
    showPosition() {
            if (!("geolocation" in navigator)) {
                this.errorStr = 'Geolocation is not available.';
                return;
            }
            this.gettingLocation = true;
            // get position
            navigator.geolocation.getCurrentPosition(pos => {
                this.gettingLocation = false;
                this.location = pos;
            }, err => {
                this.gettingLocation = false;
                this.errorStr = err.message;
            })

        }
  }
}
</script>
