<template>
    <div class="fmbottom">
        <div class="fmbottom__items">
            <div class="fmbottom__items-tabs">
                <div @click="$router.push({name: 'home'})" :class="$route.name === 'home' ? 'active' : ''" class="fmbottom__items-tabs-tab">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.5 12.1537C1.5 11.8751 1.61176 11.6063 1.81375 11.3992L11.5915 1.37338C12.077 0.875541 12.923 0.875541 13.4085 1.37338L23.1862 11.3992C23.3882 11.6063 23.5 11.8751 23.5 12.1537V21.8721C23.5 22.495 22.9528 23 22.2778 23H17.8378C17.2296 23 16.7139 22.5873 16.6279 22.0316L15.7055 16.073C15.6194 15.5174 15.1038 15.1046 14.4955 15.1046H10.5045C9.89623 15.1046 9.38055 15.5174 9.29453 16.073L8.37213 22.0316C8.28611 22.5873 7.77043 23 7.16219 23H2.72222C2.04721 23 1.5 22.495 1.5 21.8721V12.1537Z"
                            fill="#9BA1A8" />
                    </svg>
                    <p class="f-10">Главная</p>

                </div>
                <div class="fmbottom__items-tabs-tab">
                    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M22.4577 12.922C22.5472 12.4138 21.8409 12.1785 21.4854 12.5526C20.0245 14.0899 17.9393 15.0001 15.625 15.0001C11.2067 15.0001 7.625 11.4184 7.625 7.00014C7.625 4.68583 8.53518 2.60064 10.0725 1.13973C10.4466 0.784257 10.2113 0.0778929 9.70307 0.167442C4.54425 1.07648 0.625 5.5806 0.625 11.0001C0.625 17.0752 5.54987 22.0001 11.625 22.0001C17.0445 22.0001 21.5487 18.0808 22.4577 12.922Z"
                            fill="#9BA1A8" />
                    </svg>


                    <p class="f-10">
                        Мой иман
                    </p>

                </div>
                <div @click="$router.push({name: 'Shop'})" :class="$route.name === 'Shop' ? 'active' : ''" class="fmbottom__items-tabs-tab">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.92767 1H1.5V3.21053H5.02022L7.05608 13.3747C7.15956 13.8913 7.61386 14.2632 8.14151 14.2632H20.3176C20.8118 14.2632 21.2462 13.936 21.3819 13.4615L23.4574 6.20824C23.5528 5.87477 23.4859 5.5159 23.2768 5.23902C23.0676 4.96214 22.7404 4.79934 22.3931 4.79934H7.59613L7.01309 1.8885C6.90961 1.37188 6.45532 1 5.92767 1Z"
                            fill="#9BA1A8" />
                        <path
                            d="M6.48113 19.2368C6.48113 20.7629 7.72009 22 9.24842 22C10.7768 22 12.0157 20.7629 12.0157 19.2368C12.0157 17.7108 10.7768 16.4737 9.24842 16.4737C7.72009 16.4737 6.48113 17.7108 6.48113 19.2368Z"
                            fill="#9BA1A8" />
                        <path
                            d="M16.4434 19.2368C16.4434 20.7629 17.6824 22 19.2107 22C20.739 22 21.978 20.7629 21.978 19.2368C21.978 17.7108 20.739 16.4737 19.2107 16.4737C17.6824 16.4737 16.4434 17.7108 16.4434 19.2368Z"
                            fill="#9BA1A8" />
                    </svg>

                    <p class="f-10">
                        Магазин
                    </p>

                </div>
                <div @click="$router.push({name: 'Auth'})" class="fmbottom__items-tabs-tab">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.5316 23H13.4684C14.0157 23 14.4157 22.6736 14.5315 22.1368L15.079 19.821C15.4894 19.6842 15.8894 19.5263 16.2368 19.3578L18.2683 20.6C18.7211 20.8842 19.2368 20.8421 19.6157 20.4631L20.9736 19.1157C21.3526 18.7368 21.4052 18.1895 21.1 17.7368L19.8578 15.7263C20.0368 15.3579 20.1948 14.979 20.3105 14.6L22.6473 14.0421C23.1842 13.9263 23.5 13.5263 23.5 12.9789V11.0737C23.5 10.5368 23.1842 10.1368 22.6473 10.021L20.3315 9.46314C20.1947 9.03156 20.0263 8.64208 19.8789 8.31577L21.121 6.27367C21.4158 5.82105 21.3842 5.30525 20.9947 4.92631L19.6157 3.56841C19.2263 3.21052 18.7631 3.14737 18.2999 3.41052L16.2368 4.6842C15.9 4.50525 15.5104 4.34736 15.079 4.21052L14.5315 1.86316C14.4157 1.32631 14.0157 1 13.4684 1H11.5316C10.9842 1 10.5842 1.32631 10.4579 1.86316L9.9105 4.18946C9.49999 4.32631 9.09998 4.4842 8.74209 4.67367L6.69999 3.41052C6.23683 3.14737 5.76315 3.2 5.37368 3.56841L4.00526 4.92631C3.61578 5.30525 3.57368 5.82105 3.87894 6.27367L5.11052 8.31577C4.97368 8.64208 4.80526 9.03156 4.66841 9.46314L2.35263 10.021C1.81579 10.1368 1.5 10.5368 1.5 11.0737V12.9789C1.5 13.5263 1.81579 13.9263 2.35263 14.0421L4.68946 14.6C4.80526 14.979 4.96315 15.3579 5.13157 15.7263L3.89999 17.7368C3.5842 18.1895 3.64737 18.7368 4.02631 19.1157L5.37368 20.4631C5.75262 20.8421 6.27893 20.8842 6.73157 20.6L8.75262 19.3578C9.11051 19.5263 9.49999 19.6842 9.9105 19.821L10.4579 22.1368C10.5842 22.6736 10.9842 23 11.5316 23ZM12.5 15.7157C10.4579 15.7157 8.78419 14.0315 8.78419 11.9894C8.78419 9.95788 10.4579 8.28419 12.5 8.28419C14.5421 8.28419 16.2157 9.95788 16.2157 11.9894C16.2157 14.0315 14.5421 15.7157 12.5 15.7157Z"
                            fill="#9BA1A8" />
                    </svg>


                    <p class="f-10">
                        Настройки
                    </p>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>