import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    // component: HomeView
    // component: () => import('../components/loader.vue')
    component: () => import('../views/ShopView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/catalog/:subCategory',
    name: 'catalog',
    component: () => import('../views/CatalogView.vue')
  },
  {
    path: '/catalog/:subCategory/:slug',
    name: 'catalogBySlug',
    component: () => import('../views/CatalogProducts.vue')
  },
  {
    path: '/products',
    name: 'allProducts',
    component: () => import('../views/FilterProducts.vue')
  },
  {
    path: '/product/:slug',
    name: 'productbySlug',
    component: () => import('../views/ProductView.vue')
  },
  {
    path: '/delivery',
    name: 'Delivery',
    component: () => import('../views/Delivery.vue')
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('../views/Payment.vue')
  },
  {
    path: '/payment/success',
    name: 'PaymentSuccess',
    component: () => import('../views/payment/Success.vue')
  },
  {
    path: '/payment/failed',
    name: 'PaymentFailed',
    component: () => import('../views/payment/Failed.vue')
  },
  {
    path: '/payment/loading',
    name: 'PaymentLoading',
    component: () => import('../views/payment/Loading.vue')
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/auth/Authorization.vue')
  },
  {
    path: '/auth/forget',
    name: 'Forget',
    component: () => import('../views/auth/Forget.vue')
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('../views/ShopView.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../components/locationPicker.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


export default router
