<template>
  <div class="cards">
    <div class="cards__content">
      <div v-if="productCard" class="cards__items">
        <div
          @click.stop="routeToProduct(productCard)"
          class="cards__items-product"
        >
          <div class="cards__items-product-img">
            <img :src="cdn + productCard.image" alt="" />
          </div>
          <div class="cards__items-product-text ctext prt">
            <div class="prt__t">
              <h1 class="f-12">{{ productCard.title }}</h1>
              <button @click.stop="addFavorite(productCard)">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.7983 6.17104C14.9322 5.73266 15 4.9905 15 4.52941C15 2.76471 13.6 1 11.5 1C9.4 1 8.7 2.05882 8 3.11765L7.9985 3.11539C7.3 2.05882 6.59776 1 4.5 1C2.4 1 1 2.76471 1 4.52941C1 4.9905 1.06783 5.73266 1.20173 6.17104C1.89653 9.22395 7.42329 12.6224 8 13C8.57671 12.6224 14.1035 9.22395 14.7983 6.17104Z"
                    :stroke="favoriteActive ? '#F61D85' : '#80878E'"
                    :fill="favoriteActive ? '#F61D85' : ''"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div class="prt__d">
              <h2 class="f-12">{{ productCard?.category }}</h2>
            </div>
            <div class="prt__r f-12">
              <span
                ><svg
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.36459 1.378C5.61478 0.835589 6.38568 0.835589 6.63587 1.378L7.81107 3.92583C7.91304 4.1469 8.12254 4.29911 8.3643 4.32777L11.1506 4.65813C11.7438 4.72846 11.982 5.46163 11.5434 5.86719L9.48346 7.7722C9.30472 7.93749 9.2247 8.18377 9.27215 8.42255L9.81897 11.1746C9.93538 11.7604 9.31171 12.2136 8.79048 11.9218L6.34214 10.5513C6.12971 10.4324 5.87075 10.4324 5.65832 10.5513L3.20999 11.9218C2.68876 12.2136 2.06509 11.7604 2.1815 11.1746L2.72832 8.42255C2.77576 8.18377 2.69574 7.93749 2.51701 7.7722L0.457031 5.86719C0.018481 5.46163 0.256703 4.72846 0.849878 4.65813L3.63617 4.32777C3.87792 4.29911 4.08742 4.1469 4.18939 3.92583L5.36459 1.378Z"
                    fill="#1D8EF6"
                  />
                </svg>
                <p>{{ productCard.reviews_count }} отзывов</p>
              </span>
            </div>
            <div @click.stop="" class="ctext__price f-12">
              <div class="ctext__price-count prp">
                <div class="ctext__price-count-actual prp__a">
                  <p v-if="productCard.price">
                    {{ formatPrice(productCard.price) }} ₸
                  </p>
                </div>
                <!-- <div class="ctext__price-count-credit prp__c">
                                    <span class="ctext__price-count-credit-yellow prp__c-y">433 ₸</span><span
                                        class="ctext__price-count-credit-silver prp__c-s">х24</span>
                                </div> -->
              </div>
              <div class="ctext__price-btn">
                <span class="f-12 btn_quantity" v-if="activeCart"
                  ><button
                    type="button"
                    class="btn_quantity-minus"
                    @click="minusValue(productCard)"
                  >
                    <svg
                      width="12"
                      height="14"
                      viewBox="0 0 12 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.1426 2.93089H4.23857V1.87125C4.23857 1.56849 4.4505 1.36262 4.77748 1.36262H6.94522C7.27217 1.36262 7.4841 1.56849 7.4841 1.87125V2.93089H8.57401V1.8107C8.57401 0.896377 7.97455 0.333252 7.00574 0.333252H4.71087C3.74206 0.333252 3.1426 0.896377 3.1426 1.8107V2.93089ZM0.454133 3.65145H11.2746C11.5229 3.65145 11.7287 3.43952 11.7287 3.19126C11.7287 2.943 11.5229 2.73713 11.2746 2.73713H0.454133C0.211929 2.73713 0 2.943 0 3.19126C0 3.44557 0.211929 3.65145 0.454133 3.65145ZM10.3724 3.52429H1.3624L1.75598 12.4979C1.78626 13.2488 2.24039 13.6666 2.98516 13.6666H8.74359C9.4944 13.6666 9.94852 13.2488 9.97884 12.4979L10.3724 3.52429ZM3.94793 11.9348C3.72994 11.9348 3.57856 11.7955 3.57251 11.5776L3.3848 4.98357C3.37875 4.77164 3.53013 4.62632 3.76628 4.62632C3.97821 4.62632 4.13563 4.76559 4.14169 4.97751L4.32335 11.5776C4.3294 11.7895 4.17802 11.9348 3.94793 11.9348ZM5.86739 11.9348C5.6373 11.9348 5.47381 11.7895 5.47381 11.5776V4.98357C5.47381 4.77164 5.6373 4.62632 5.86739 4.62632C6.09749 4.62632 6.25493 4.77164 6.25493 4.98357V11.5776C6.25493 11.7895 6.09749 11.9348 5.86739 11.9348ZM7.78079 11.9348C7.55069 11.9348 7.39934 11.7895 7.40542 11.5776L7.58703 4.98357C7.5931 4.76559 7.75053 4.62632 7.96246 4.62632C8.19256 4.62632 8.34999 4.77164 8.34391 4.98357L8.15623 11.5776C8.15015 11.7955 7.9988 11.9348 7.78079 11.9348Z"
                        fill="#1D8EF6"
                      />
                    </svg>
                  </button>
                  <p>{{ productCard.count ? productCard.count : count }}</p>
                  <button
                    class="btn_quantity-plus"
                    @click="plusValue(productCard)"
                  >
                    +
                  </button>
                </span>
                <button
                  v-if="activeCart"
                  type="button f-12 ctext__price-btn-del"
                  class="btn_quantity"
                  @click.prevent="removeValue(productCard)"
                >
                  Удалить
                </button>
                <button
                  class="f-12 btn_blue"
                  @click.prevent="addToCart(productCard)"
                  v-else
                >
                  В корзину
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  props: ["productCard"],
  data: () => ({
    favoriteActive: false,
    activeCart: false,
    count: 1,
  }),
  created() {
    this.CHECK_FAVOURITE_LIST();
    if (!localStorage.getItem("cart_products")) {
      localStorage.setItem("cart_products", "[]");
    }
  },
  mounted() {
    this.inTheCart();

    this.activeFavorite;
    let localStorageProducts = JSON.parse(
      localStorage.getItem("cart_products")
    );
  },
  computed: {
    ...mapGetters({
      cartList: "GET_CART_LIST",
      cdn: "GET_CDN",
      basket: "GET_BASKET_OPENED",
      favoriteList: "GET_FAVOURITE_LIST",
    }),
    activeFavorite() {
      if (this.favoriteList) {
        this.favoriteList.filter((i) => {
          if (this.productCard) {
            if (i.id === this.productCard.id) {
              this.favoriteActive = true;
            }
          }
        });
      }
    },
  },
  methods: {
    ...mapActions(["DELETE_PRODUCT","CHECK_FAVOURITE_LIST"]),
    ...mapMutations(["ADD_FAVORITES"]),
    routeToProduct(value) {
      if (this.basket) [this.$store.commit("showHide")];
      localStorage.setItem('lastRoute',this.$route.fullPath)
      this.$router.push({
        name: "productbySlug",
        params: { slug: value.slug },
      });
    },
    addFavorite(value) {
      this.favoriteActive = !this.favoriteActive;
      this.ADD_FAVORITES(value);
    },
    addToCart(product) {
      console.log('product',product);
      this.$store.commit('showHide5')
      // this.activeCart = !this.activeCart;
      // this.$store.commit("ADD_TO_CART", { product, count: this.count });
    },
    minusValue(value) {
      if (this.count > 1) {
        this.count--;
        this.$store.commit("SET_CART_COUNT", {
          id: value.id,
          count: this.count,
        });
      } else if (this.count === 1) {
        this.activeCart = !this.activeCart;
        this.$store.commit("DELETE_PRODUCT", { id: value.id });
        this.count = 1;
        this.$emit("DELETE_FROM_BASKET");
      }
    },
    plusValue(value) {
      this.count++;
      this.$store.commit("SET_CART_COUNT", { id: value.id, count: this.count });
    },
    removeValue(value) {
      this.$store.commit("DELETE_PRODUCT", { id: value.id });
      this.count = 1;
      this.$emit("DELETE_FROM_BASKET");
    },
    inTheCart() {
      if (this.cartList && this.productCard) {
        let findCart = this.cartList.find((i) => i.id === this.productCard.id);
        if (findCart) {
          this.activeCart = true;
          this.count = findCart.count;
        } else {
          this.activeCart = false;
        }
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    inTheFavourite() {
      if (this.favoriteList && this.productCard) {
        let findCart = this.favoriteList.find(
          (i) => i.id === this.productCard.id
        );
        if (findCart) {
          this.favoriteActive = true;
        } else {
          this.favoriteActive = false;
        }
      }
    },
  },
  watch: {
    cartList() {
      this.inTheCart();
    },
    favoriteList() {
      this.inTheFavourite();
    },
    productCard() {
      this.inTheCart();
    },
  },
};
</script>

<style></style>
