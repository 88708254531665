import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import "swiper/css";
import 'swiper/swiper.min.css';
import 'swiper/scss/navigation';
import "swiper/css/free-mode";
import "swiper/css/pagination";
import axios from 'axios';
import i18n from '@/plugins/i18n';
import VueTheMask from 'vue-the-mask'
import VOtpInput from "vue3-otp-input";
import VueCookies from 'vue-cookies'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import veProgress from 'vue-ellipse-progress';

const lang = localStorage.getItem('lang') || 'ru';
axios.defaults.baseURL = process.env.VUE_APP_APN;
axios.defaults.params = {
    'lang': lang,
  };
axios.defaults.headers = {
  Authorization: `Bearer ${$cookies.get("token")}`
}

const options = {
  position: "top-center",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: true,
  closeButton: false,
  icon: false,
  rtl: false
};

createApp(App)
  .use(store)
  .use(veProgress)
  .use(router)
  .use(i18n)
  .use(VueTheMask)
  .component('v-otp-input', VOtpInput)
  .use(VueCookies, { expires: '7d'})
  .use(Toast, options)
  .mount('#app')
