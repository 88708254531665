<template>
    <div class="loading">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>

</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.loading{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.24);
    opacity: 0.5;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0;
    height: 0;
    z-index: 999!important;
    width: 100vw;
    height: 100vh;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
  opacity: 1;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>